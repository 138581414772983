.form-input {
  display: flex;
  flex-direction: column;
  margin: 0 0 rem(24) 0;

  &__input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  label {
    @extend %body-highlighted;

    margin-bottom: rem(8);
    display: flex;
    gap: rem(4);
  }

  input {
    @extend %body-body5;

    padding: rem(10) rem(16);
    background: var(--input-bg-color);
    border: 2px solid var(--input-border-color);
    border-radius: rem(8);

    &:focus,
    &:active {
      outline: none;
      border-color: var(--input-border-color--active);
    }
  }

  &__error-list {
    list-style: none;
    margin: rem(8) 0 0 0;
    padding: 0;

    li {
      @extend %body-body;
      color: var(--alert-main);

      a {
        color: var(--alert-main);
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }

  &__warning-list {
    list-style: none;
    margin: rem(8) 0 0 0;
    padding: 0;

    li {
      @extend %body-body;
      color: var(--warning);

      a {
        color: var(--warning);
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }

  &--required {
    label {
      &::after {
        @extend %body-highlighted;
        content: '*';
      }
    }
  }

  &--disabled {
    input {
      background: $color-grey-100;
      color: $color-grey-500;
    }

    .form-input__clear {
      display: none;
    }
  }

  &--error {
    input {
      border-color: var(--alert-main);

      &:focus,
      &:active {
        border-color: var(--alert-main);
      }
    }
  }

  &--warning {
    input {
      border-color: var(--warning);

      &:focus,
      &:active {
        border-color: var(--warning);
      }
    }
  }

  &--clearable {
    position: relative;

    .form-input__clear {
      position: absolute;
      right: rem(9);
      bottom: rem(9);
      z-index: 10;
      cursor: pointer;
    }
  }
}
