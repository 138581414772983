html,
body,
#root {
  height: 100%;
}

.frame {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  align-items: center;

  &--unauthenticated {
    background: var(--bg-color-webview);
    gap: 0;

    @include screenSize(tablet) {
      gap: rem(24);
      background: transparent;
    }
  }

  &__register-header {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__header {
    width: 100%;
    z-index: 10;

    @include screenSize(desktop) {
      max-width: rem(1200);
      padding: 0 rem(24);
    }

    @include screenSize(highres) {
      max-width: rem(1600);
    }
  }

  &__content {
    justify-content: center;
    display: flex;
    width: 100%;
    max-width: rem(1200);
    height: 100%;

    @include screenSize(highres) {
      max-width: rem(1600);
    }
  }
}
