@import '@ytl/common-web/dist/styles/base/typo';

.register-page {
  width: 100%;
  height: 100%;
  border-radius: rem(16) rem(16) 0 0;

  @include screenSize(tablet) {
    // we need to pop out this container to the top, because of the lime gradient
    z-index: 1;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: rem(40);
    gap: rem(40);
    justify-content: center;
    flex-wrap: wrap;
    overflow: auto;

    > div {
      position: relative;
    }

    @include screenSize(tablet) {
      flex-wrap: nowrap;
    }
  }

  &__container {
    flex: 1 1 50%;
    max-width: rem(550);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include screenSize(tablet) {
      min-height: calc(100% + 124px);
    }

    &__box {
      padding: 0;
      background: var(--bg-color-box);
      border-radius: rem(16);
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: rem(490);
      height: fit-content;
      gap: rem(24);

      @include screenSize(tablet) {
        padding: rem(40);
      }

      h3 {
        @extend %headings-l3;
        margin: 0;
      }

      p {
        margin: 0;
      }

      &__inputs {
        .form-input {
          margin-bottom: rem(16);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &__password-hint {
        display: none;
      }

      &__recaptcha {
        margin-bottom: rem(16);
        display: flex;
        justify-content: center;

        @include screenSize(midres) {
          margin-top: rem(11 * calc(410 / 300));
          margin-bottom: rem(12 * calc(410 / 300));
          transform: scale(calc(410 / 300));
        }
      }
    }

    &__action-container {
      width: 100%;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: rem(8);
        width: 100%;

        svg {
          width: rem(24);
          height: rem(24);
        }
      }
    }

    &__error {
      font-weight: 300;
      font-size: rem(14);
      line-height: rem(20);
      color: $color-alert-main;
      //margin: rem(-24) 0 0 0;
      text-align: center;

      & + button.button--text-undecorated {
        font-weight: 400;
      }
    }

    &__divider {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      position: relative;

      span {
        @extend %body-body3;
        color: var(--grey-300);
        padding: 0 rem(16);
        background: var(--bg-color-box);
        position: relative;
      }

      &::before {
        content: '';
        width: 100%;
        height: 1px;
        background: var(--grey-300);
        position: absolute;
        left: 0;
        right: 0;
      }
    }

    &__icon-result {
      svg {
        display: block;
        margin-bottom: rem(16);
      }

      span {
        @extend %headings-l3;
      }
    }

    &__result-desc {
      @extend %body-body6;
      //color: $color_navyblue;
      color: inherit;
      margin: rem(16) 0 rem(40) 0;

      button {
        display: block;
        color: inherit;
        margin: rem(16) 0 0 0;
      }
    }
  }
}
