.button {
  @extend %headings-l5;

  padding: rem(12) rem(20);
  text-align: center;
  cursor: pointer;

  &--contained {
    border: none;
    border-radius: $border-radius-button-default;
    color: var(--button-contained-text-color);
    background: var(--button-highlight-color);

    &:disabled {
      background: $color-grey-100 !important;
      color: $color-grey-500 !important;
    }
  }

  &--text {
    color: var(--button-highlight-color);
    font-weight: 300;
    text-decoration: underline;
    border: none;
    background: transparent;

    &:disabled {
      background: transparent !important;
      color: $color-grey-500 !important;
    }
  }

  &--text-undecorated {
    color: var(--button-highlight-color);
    font-weight: 300;
    border: none;
    background: transparent;
    text-decoration: none;

    &:disabled {
      background: transparent !important;
      color: $color-grey-500 !important;
    }
  }

  &--outlined {
    border: solid rem(2) var(--button-highlight-color);
    border-radius: $border-radius-button-default;
    color: var(--button-highlight-color);
    background: transparent;
    padding: rem(10) rem(20);

    &:disabled {
      color: $color-grey-100 !important;
      border-color: $color-grey-100 !important;
      background: transparent !important;
    }
  }

  &--danger {
    border: none;
    color: $color-white;
    background: var(--alert-main);

    &:disabled {
      background: $color-grey-100 !important;
      color: $color-grey-500 !important;
    }
  }

  &--small.button {
    font-size: rem(14);
    line-height: rem(20);
    padding: rem(8) rem(16);

    .loader {
      width: rem(30);
      svg {
        transform: scale(0.3);
      }
    }
  }

  &:disabled {
    background: $color-grey-100;
    color: $color-grey-500;
  }

  &--no-left-right-padding {
    padding-left: 0;
    padding-right: 0;
  }

  &--no-padding {
    padding: 0;
  }
}
