.tabs {
  display: flex;
  justify-content: flex-start;

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &__item {
      @extend %headings-l5;
      color: $color_navyblue;
      padding: rem(8) rem(36) rem(28) rem(36);
      border-bottom: rem(2) solid $color-sky;

      &--selected {
        border-bottom-color: $color_navyblue;
      }

      &:hover {
        cursor: pointer;
        border-bottom-color: $color_navyblue;
      }
    }
  }
}
