.mfa-setup-page {
  align-self: center;
  justify-self: center;
  width: 100%;
  height: auto;
  margin: rem(16);

  @include screenSize(tablet) {
    width: rem(480);
    height: auto;
  }

  .box {
    height: 100%;
    border-radius: rem(16);

    &__header {
      font-size: rem(24);
      font-weight: 700;
      line-height: rem(32);
      letter-spacing: -0.02em;
    }
  }

  .phone-number-input .form-input {
    margin-bottom: 0;
  }

  &--successSMS {
    .box__action {
      margin-top: rem(16);
    }
  }

  .setup__link {
    color: $color-navyblue;
    text-decoration: underline;
    font-weight: 400;
    font-size: rem(14);
  }

  .qr-code__container {
    border-radius: rem(8);
    width: 100%;
    background: $color-warning-light;
    display: flex;
    flex-direction: column;
    gap: rem(32);
    align-items: center;
    padding: rem(16) 0;

    &__qr-wrapper {
      padding: rem(24);
      border-radius: rem(16);
      background: $color-white;
    }

    &__raw-code-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__raw-code {
      font-weight: 700;
      font-size: rem(20);
      margin-bottom: rem(16);
    }
  }
}
