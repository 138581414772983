.account-settings {
  gap: rem(24);
  display: flex;
  flex-direction: column;

  .data-card:last-child {
    margin-bottom: rem(24);
  }

  .account-data {
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include screenSize(tablet) {
      justify-content: space-between;
      flex-direction: row;
    }

    &__editor {
      width: 100%;

      @include screenSize(tablet) {
        max-width: $column-width;
      }
    }

    &__button-row {
      margin: 0 0 rem(24) 0;
      align-self: flex-end;
      display: flex;

      button:last-of-type {
        margin-left: rem(16);
      }

      @include screenSize(tablet) {
        margin-left: rem(24);
        align-self: flex-start;
      }
    }

    &--account-name {
      border-bottom: rem(1) solid $color-grey-100;
    }

    &--email-address {
      margin: rem(24) 0 0 0;

      .account-data__button-row {
        margin-bottom: 0;
      }

      @include screenSize(tablet) {
        .form-input {
          margin-bottom: 0;
        }
      }
    }

    &--password-info {
      legend {
        @extend %body-body5;
      }

      p {
        @extend %body-body5;
        margin: 0;
      }

      .account-data__button-row {
        margin: 0;
      }
    }
  }

  .alm-button-container {
    display: flex;
    flex-wrap: wrap;
    gap: rem(16);
    justify-content: flex-end;

    button {
      width: rem(336);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: rem(8);

      svg {
        width: rem(24);
        height: rem(24);
      }
    }
  }

  .alm-info-container {
    display: flex;
    flex-direction: column;
    gap: rem(16);

    .info-text {
      text-align: center;
      margin: 0;
    }
  }
}
