.account-home {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include screenSize(tablet) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__add-phonenumber {
    width: 100%;
    border-radius: rem(16);
    background: $color_sky;
    padding: rem(32);
    margin-bottom: rem(16);

    @include screenSize(tablet) {
      min-width: rem(391);
      border-radius: rem(16) 0 0 rem(16);
      flex: 1 1;
      margin-bottom: 0;
    }

    h3 {
      @extend %headings-l3;
      color: $color_navyblue;
      letter-spacing: rem(-0.56);
      margin: 0 0 rem(8);
    }

    .form-input {
      margin: 0;

      input {
        @extend %body-body6;
        color: $color-grey-500;
      }
      label {
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(20);
        color: $color-navyblue;
        svg {
          position: relative;
          top: rem(4);
          padding-left: rem(4);
        }
      }
    }

    &__phone-number-input {
      margin-bottom: rem(16);
    }

    &__code-input {
      margin-bottom: rem(4);

      .form-input {
        margin-bottom: 0;
      }
    }

    &__default-actions {
      p {
        color: $color-grey-700;
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(24);
        margin: 0 0 rem(16);
      }

      button {
        display: block;
        width: 100%;
      }
      button.button--contained {
        margin-bottom: rem(16);
      }
      button.button--text {
        font-weight: 400;
        font-size: rem(16);
        line-height: rem(24);
        color: $color-grey-500;
        margin: rem(12) 0 rem(12);
        text-decoration: none;

        &:disabled {
          background: transparent;
          opacity: 0.4;
        }
      }
    }

    &__sms-warning {
      border-radius: rem(16);
      padding: rem(16);
      margin: 0 0 rem(8) 0;
      background: $color-warning-light;

      h4 {
        @extend %headings-l6;
        margin: 0;
      }
      p {
        @extend %body-body7;
        margin: 0;
      }
      p strong {
        @extend %body-body7;
        font-weight: 400;
      }
      ul {
        margin: 0;
        padding: 0 0 0 rem(24);
        li {
          @extend %body-body7;
        }
      }
    }
  }

  &__phone-number-info {
    font-weight: 700;
    font-size: rem(16);
    line-height: rem(24);
    margin-bottom: rem(16);
    text-align: center;

    @include screenSize(tablet) {
      display: none;
    }
  }

  &__phonenumbers-desktop {
    display: none;

    @include screenSize(tablet) {
      background: $color_white;
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      border-radius: 0 rem(16) rem(16) 0;
      padding: rem(16);
    }

    &__table {
      width: 100%;
      thead td {
        text-align: center;

        color: $color-grey-600;
        font-size: rem(16);
        font-style: normal;
        font-weight: 400;
        line-height: rem(24);
      }

      tbody td {
        color: #000;
        font-size: rem(16);
        font-weight: 700;
        line-height: rem(24);
      }

      td.isLeft {
        text-align: left;
      }
      td.isCenter {
        text-align: center;
      }
      td.isSuccess {
        color: $color-success;
        svg {
          height: rem(20);
          width: rem(20);
          position: relative;
          left: rem(4);
          top: rem(4);
        }
      }
      td.isWarning {
        color: $color-warning;
        svg {
          height: 20px;
          position: relative;
          left: rem(4);
          top: rem(2);
        }
      }
    }

    &__phone-number-info {
      display: none;

      @include screenSize(tablet) {
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(24);
        display: block;
        text-align: center;
        margin: rem(36);
        color: $color-grey-800;
      }
    }
  }

  &__phonenumbers-mobile {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: rem(12);

    @include screenSize(tablet) {
      display: none;
    }

    &__item {
      background: $color_white;
      width: 100%;
      padding: rem(16) rem(32);
      border-radius: rem(16);

      > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: rem(4);

        label {
          font-weight: 400;
          font-size: rem(16);
          line-height: rem(24);
          color: $color-grey-600;
        }

        span {
          font-weight: 700;
          font-size: rem(16);
          line-height: rem(24);
          color: #000;
          text-align: right;
        }
      }

      button {
        @extend %headings-l6;
        margin-top: rem(16);
        width: 100%;
        border-color: rgba($color-navyblue, 0.5);
        padding-top: rem(6);
        padding-bottom: rem(6);
      }
    }
  }
}
