@use 'sass:color';

@import './function';

// FONTS
$font: (
  'yettel': 'Yettel',
);

// TYPO
$typo: (
  'font': map-get($font, 'yettel'),
  'size': 16,
  'weight': 400,
);

// BREAKPOINTS
$breakpoint: (
  'desktop_4': 3840px,
  'desktop_3': 2560px,
  'desktop_2': 1920px,
  'desktop_1': 1650px,
  'laptop_2': 1366px,
  'laptop_1': 1024px,
  'tablet_2': 960px,
  'tablet_1': 768px,
  'mobile_3': 640px,
  'mobile_2': 480px,
  'mobile_1': 320px,
);

$border-radius-button-default: 24px;

//$color-grey-90: #e5e5e5;
$color-grey-100: #e5e9ec;
$color-grey-300: #b3bdc6;
$color-grey-500: #80919f;
$color-grey-600: #667b8c;
$color-grey-700: #4d6579;
$color-grey-800: #334f66;
$color-shinyblue: #01385e;
$color-navyblue: #002340;
$color-sky: #c4dfe9;
$color-white: #fff;
$color-lime: hsla(78, 100%, 50%, 1);
$color-success: #54bf78;
$color-alert-main: #ff5728;
$color-warning: #ffba0f;
$color-warning-light: #fff6cf;
$color-steel-gray: #ccd3d9;
$color-shinyblue: #01385e;
