.email-change-page {
  padding: rem(40);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: rem(24);

  h3 {
    font-weight: 700;
    font-size: rem(20);
    line-height: rem(28);
    margin: 0;
  }

  > p {
    font-weight: 300;
    font-size: rem(14);
    line-height: rem(20);
    margin: 0;
  }

  .form-input {
    margin-bottom: 0;
  }
}
