.icon {
  &--success {
    color: $color-success;
  }
  &--error {
    color: $color-alert-main;
  }

  &--envelope {
    color: $color-navyblue;
  }
}
