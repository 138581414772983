.box {
  display: flex;
  flex-direction: column;
  padding: rem(40);
  gap: 0;
  background: var(--bg-color-box);

  @include screenSize(tablet) {
    border-radius: rem(16);
  }
  &__devicecode {
    padding: rem(8);
  }
  &__icon {
    margin-bottom: rem(16);
  }

  &__header {
    @extend %headings-l3;
  }

  &__sub-header {
    @extend %body-body;
    margin-top: rem(16);
  }

  &__body {
    @extend %body-body;
    margin-top: rem(24);

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    margin-top: rem(40);
    gap: rem(16);

    button {
      flex: 1;
    }
  }

  &__sub-action {
    display: flex;

    button {
      flex: 1;
    }
  }
}
