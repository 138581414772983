.data-card {
  background: $color-white;
  display: flex;
  flex-direction: column;
  padding: rem(24) rem(16);
  //margin: 0 0 rem(24) 0;
  border-radius: rem(16);

  @include screenSize(tablet) {
    flex-direction: row;
    padding: rem(40);
  }

  &__header {
    @extend %headings-l4;
    flex: 1;
    margin-bottom: rem(24);

    @include screenSize(tablet) {
      min-width: rem(200);
      flex: 1 1 25%;
    }

    @include screenSize(desktop) {
      min-width: rem(320);
    }
  }

  &__content {
    flex: 1 1 100%;
  }
}
