@mixin screenSize($media) {
  @if $media == highres {
    @media screen and (min-width: map-get($breakpoint, 'desktop_1')) {
      @content;
    }
  } @else if $media == midres {
    @media screen and (min-width: map-get($breakpoint, 'laptop_2')) {
      @content;
    }
  } @else if $media == desktop {
    @media screen and (min-width: map-get($breakpoint, 'laptop_1')) {
      @content;
    }
  } @else if $media == tablet {
    @media screen and (min-width: map-get($breakpoint, 'tablet_1')) {
      @content;
    }
  } @else if $media == onMobile {
    @media screen and (max-width: map-get($breakpoint, 'tablet_1')) {
      @content;
    }
  }
}
