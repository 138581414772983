.info-card {
  background: $color-white;
  display: flex;
  flex-direction: column;
  padding: rem(24) rem(16);
  border-radius: rem(16);

  &__header {
    @extend %headings-l4;
    margin-bottom: rem(24);
    padding-left: rem(24); // header balról beljebb
  }
}

.info-card-content {
  display: flex;
  flex-direction: column;

  .info-text {
    text-align: left; // szöveg balra igazítva
    margin: 0;
    padding-left: rem(24); // tartalom balról beljebb
  }
}
