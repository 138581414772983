.phone-number-input {
  .form-input {
    position: relative;
    //margin-bottom: 0;

    &__input-container {
      position: relative;

      &::after {
        font-style: normal;
        font-weight: 300;
        font-size: rem(16);
        line-height: rem(20);

        position: absolute;
        content: '+36';
        color: $color-grey-500;
        bottom: rem(14);
        left: rem(11);
      }
    }

    input {
      padding-left: rem(38);
    }
  }
}
