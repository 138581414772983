.ytl--dark {
  color-scheme: dark;

  --white: #ffffff;

  //--grey-90: #e5e5e5;
  --grey-100: #e5e9ec;
  --grey-300: #b3bdc6;
  --grey-500: #80919f;
  --grey-600: #667b8c;
  --grey-700: #4d6579;
  --grey-800: #334f66;
  --lime: #b4ff00;
  --navyblue: #002340;
  //$color-shinyblue: #01385e;
  //$color-navyblue: #002340;
  //$color-sky: #c4dfe9;
  --success: #54bf78;
  --alert-main: #ff5728;
  --warning: #ffba0f;
  --warning-light: #fff6cf;
  --steel-gray: #ccd3d9;
  --shinyblue: #01385e;

  --bg-color: #002340;
  --bg-color-register: var(--navyblue);
  --bg-color-register-sd: var(--navyblue);
  --bg-color-webview: var(--navyblue);
  --bg-color-box: var(--navyblue);

  --text-color: var(--white);

  --input-bg-color: #002340;
  --input-border-color: #334f66;
  --input-border-color-active: #f2f4f5;

  --button-highlight-color: #b4ff00;
  --button-contained-text-color: #002340;

  --dialog-backdrop-bg-color: #000000;

  --tooltip-bg-color: black;
  --forgotten-password-color: #f2f4f5;
  --loader-color: var(--steel-gray);
}
