.form-input.otp-code-input {
  align-items: center;
  margin: 0;

  input {
    color: transparent;
    border: none;
    width: 285px;
    caret-color: transparent;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .otp-code-input__value {
    position: absolute;
    display: flex;
    gap: 16px;

    background-image: url("data:image/svg+xml,%3Csvg%20width%3D'286'%20height%3D'1'%20viewBox%3D'0%200%20286%201'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%0A%3Cline%20x1%3D'0.5'%20y1%3D'0.5'%20x2%3D'35.5'%20y2%3D'0.5'%20stroke%3D'%23002340'%2F%3E%0A%3Cline%20x1%3D'50.5'%20y1%3D'0.5'%20x2%3D'85.5'%20y2%3D'0.5'%20stroke%3D'%23002340'%2F%3E%0A%3Cline%20x1%3D'100.5'%20y1%3D'0.5'%20x2%3D'135.5'%20y2%3D'0.5'%20stroke%3D'%23002340'%2F%3E%0A%3Cline%20x1%3D'150.5'%20y1%3D'0.5'%20x2%3D'185.5'%20y2%3D'0.5'%20stroke%3D'%23002340'%2F%3E%0A%3Cline%20x1%3D'200.5'%20y1%3D'0.5'%20x2%3D'235.5'%20y2%3D'0.5'%20stroke%3D'%23002340'%2F%3E%0A%3Cline%20x1%3D'250.5'%20y1%3D'0.5'%20x2%3D'285.5'%20y2%3D'0.5'%20stroke%3D'%23002340'%2F%3E%0A%3C%2Fsvg%3E");
    width: 285px;
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 285px 1px;
    height: 36px;

    > span {
      width: 36px;
      font-variant-numeric: tabular-nums;
      font-size: rem(28);
      font-weight: 700;
      line-height: rem(36);
      text-align: center;
    }
  }

  &--error {
    .otp-code-input__value {
      color: var(--alert-main);
    }
  }
}

html.ytl--dark .form-input.otp-code-input .otp-code-input__value {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D'286'%20height%3D'1'%20viewBox%3D'0%200%20286%201'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%0A%3Cline%20x1%3D'0.5'%20y1%3D'0.5'%20x2%3D'35.5'%20y2%3D'0.5'%20stroke%3D'%23ffffff'%2F%3E%0A%3Cline%20x1%3D'50.5'%20y1%3D'0.5'%20x2%3D'85.5'%20y2%3D'0.5'%20stroke%3D'%23ffffff'%2F%3E%0A%3Cline%20x1%3D'100.5'%20y1%3D'0.5'%20x2%3D'135.5'%20y2%3D'0.5'%20stroke%3D'%23ffffff'%2F%3E%0A%3Cline%20x1%3D'150.5'%20y1%3D'0.5'%20x2%3D'185.5'%20y2%3D'0.5'%20stroke%3D'%23ffffff'%2F%3E%0A%3Cline%20x1%3D'200.5'%20y1%3D'0.5'%20x2%3D'235.5'%20y2%3D'0.5'%20stroke%3D'%23ffffff'%2F%3E%0A%3Cline%20x1%3D'250.5'%20y1%3D'0.5'%20x2%3D'285.5'%20y2%3D'0.5'%20stroke%3D'%23ffffff'%2F%3E%0A%3C%2Fsvg%3E");
}
