@font-face {
  font-family: Yettel;
  src:
    url('../../assets/font/yettel/bold.woff2') format('woff2'),
    url('../../assets/font/yettel/bold.woff2') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: Yettel;
  src:
    url('../../assets/font/yettel/light.woff2') format('woff2'),
    url('../../assets/font/yettel/light.woff2') format('woff');
  font-weight: 300;
}
@font-face {
  font-family: Yettel;
  src:
    url('../../assets/font/yettel/regular.woff2') format('woff2'),
    url('../../assets/font/yettel/regular.woff2') format('woff');
  font-weight: 400;
}

body * {
  font-family: map-get($typo, 'font'), sans-serif;
  font-size: rem(map-get($typo, 'size'));
  // never set color on body, it overrides svg's fill: currentColor
  //color: $color_navyblue;
}

html {
  color: var(--text-color);
}

%fix-bolder-fonts {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
button,
input,
select,
textarea {
  @extend %fix-bolder-fonts;
}

%headings-l7 {
  font-style: normal;
  font-weight: 700;
  font-size: rem(12);
  line-height: rem(16);
}
%headings-l6 {
  font-style: normal;
  font-weight: 700;
  font-size: rem(14);
  line-height: rem(20);
}
%headings-l5 {
  font-style: normal;
  font-weight: 600;
  font-size: rem(16);
  line-height: rem(24);
}
%headings-l4 {
  font-style: normal;
  font-weight: 600;
  font-size: rem(20);
  line-height: rem(28);
}
%headings-l3 {
  font-style: normal;
  font-weight: 600;
  font-size: rem(28);
  line-height: rem(36);
}
%headings-l1 {
  font-style: normal;
  font-weight: 600;
  font-size: rem(72);
  line-height: rem(74);
  letter-spacing: rem(-1.44);
}
%body-body {
  font-style: normal;
  font-weight: 100;
  font-size: rem(14);
  line-height: rem(20);
}
%body-body3 {
  font-style: normal;
  font-weight: 100;
  font-size: rem(14);
  line-height: rem(20);
}
%body-body5 {
  font-style: normal;
  font-weight: 100;
  font-size: rem(16);
  line-height: rem(24);
}
%body-body6 {
  font-style: normal;
  font-weight: 300;
  font-size: rem(14);
  line-height: rem(20);
}
%body-body7 {
  font-style: normal;
  font-weight: 300;
  font-size: rem(12);
  line-height: rem(16);
}
%body-highlighted {
  font-style: normal;
  font-weight: 300;
  font-size: rem(14);
  line-height: rem(20);
}

.header-3 {
  @extend %headings-l3;
  margin-bottom: rem(24);
}

u,
em,
strong,
i {
  font-size: inherit;
}
