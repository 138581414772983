.loader {
  width: rem(40);
  height: auto;
  position: relative;
  display: inline-block;

  svg {
    position: absolute;
    left: rem(-30);
    transform: scale(0.5);
    top: rem(-45);

    path {
      fill: var(--loader-color);
    }
  }
}
