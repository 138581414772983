html {
  height: 100%;
  font-size: map-get($typo, 'size') * 1px;

  background: var(--bg-color);
}

body,
body * {
  box-sizing: border-box;
}
