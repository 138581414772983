.dialog {
  background: var(--bg-color-box);
  border-radius: rem(16);
  padding: rem(24);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  width: calc(100% - 2rem); // here we can't use rem() function

  @include screenSize(tablet) {
    width: rem(480);
    padding: rem(40);
  }

  &-text {
  }

  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: var(--dialog-backdrop-bg-color);
    opacity: 0.8;
    box-shadow: rem(0) rem(4) rem(32) rgba(0, 0, 0, 0.08);
  }

  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    margin-bottom: rem(16);
  }

  &__header {
    @extend %headings-l3;
    margin: 0 0 rem(16) 0;
  }

  &__sub-header {
    @extend %body-body;
    margin: 0 0 rem(16) 0;
  }

  &__body,
  &__body > span {
    @extend %body-body;

    &--empty {
      margin-bottom: rem(-20);
    }

    a {
      color: var(--navyblue);
      font-weight: 300;
      text-decoration: underline;
    }
  }

  &__actions {
    margin: rem(40) 0 0 0;
    display: flex;
    width: 100%;
    gap: rem(16);

    &--vertical {
      flex-direction: column-reverse;
      gap: rem(8);
    }

    button {
      display: block;
      flex: 1;
      width: 100%;
    }
  }
}
