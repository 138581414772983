.icon {
  &--success {
    color: $color-success;
  }

  &--error {
    color: $color-alert-main;
  }

  &--envelope {
    color: $color-navyblue;
  }

  &--tooltip {
    width: rem(17);
    height: rem(17);
    position: relative;
    top: rem(2);
  }
}
