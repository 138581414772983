html {
  &.register-page {
    background: var(--bg-color-register-sd);

    @include screenSize(tablet) {
      background: var(--bg-color-register);
    }

    &--raw.register-page {
      background: var(--bg-color);

      .frame {
        height: 100%;

        &::before {
          display: none;
        }
      }

      .register-page {
        display: flex;
        align-items: center;

        &__hero {
          display: none;
        }
      }
    }

    .frame {
      min-height: 100vh;
      overflow: hidden;

      &::before {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(350deg, var(--lime) 550px, transparent 551px);
      }

      &::after {
        position: absolute;
        width: 100%;
        bottom: 0;
        background: var(--lime);
      }

      @include screenSize(tablet) {
        &::before {
          content: ' ';
          height: 1200px;
        }

        &::after {
          content: ' ';
          top: 1200px;
        }
      }

      @include screenSize(midres) {
        &::before {
          content: ' ';
          height: 1100px;
        }

        &::after {
          content: ' ';
          top: 1100px;
        }
      }

      &__content {
        overflow: auto;
      }
    }
  }

  &.change-password-page {
    background: var(--bg-color-register-sd);

    @include screenSize(tablet) {
      background: var(--bg-color);
    }
  }
}
