#menuSwitch {
  display: none;
}

.header {
  padding: rem(16) rem(16) 0 rem(16);
  background: var(--lime);
  border-radius: 0 0 rem(20) rem(20);
  display: flex;
  flex-direction: row;
  align-items: center;

  @include screenSize(tablet) {
    padding: rem(36) rem(16) 0 rem(16);
  }

  &--b2c {
    background: var(--lime);
  }
  &--b2b {
    background: var(--navyblue);
  }

  &__logo {
    height: rem(48);
    margin-bottom: rem(16);
  }

  &__fill {
    flex: 1;
  }

  &__menu-items {
    opacity: 0;
    padding-top: rem(0);
    transform: translateY(-200%);
    transition: transform 0.3s ease-in-out;
    width: 0;
    height: 0;

    @include screenSize(tablet) {
      width: auto;
      height: auto;
      opacity: 1;
      transform: translateY(0);
    }

    ul {
      list-style: none;
      display: flex;
      margin: 0;
    }
  }

  &__menu-item {
    @extend %headings-l5;
    cursor: pointer;
    padding: rem(16) rem(36) rem(22) rem(36);
    color: var(--navyblue);

    &--b2c {
      color: var(--navyblue);
      &--selected {
        border-bottom: rem(2) solid $color-navyblue;
      }
    }
    &--b2b {
      color: var(--lime);
      &--selected {
        border-bottom: rem(2) solid $color-lime;
      }
    }
  }

  &__switch-language {
    @extend %headings-l5;
    transform: translateY(-300%) translateX(rem(20));
    transition: transform 0.3s ease-in-out;
    right: rem(36);
    margin-bottom: rem(16);
    z-index: -1;

    button {
      font-weight: 600;
      text-decoration: none;
      color: var(--navyblue);
    }

    .button {
      &--b2c {
        color: var(--navyblue);
      }
      &--b2b {
        color: var(--lime);
      }
    }

    @include screenSize(tablet) {
      transform: translateY(0) translateX(0);
      right: auto;
      z-index: 0;
    }
  }

  &__mobile-toggle {
    position: relative;
    display: inline-block;
    width: rem(40);
    height: rem(40);

    @include screenSize(tablet) {
      display: none;
    }

    label {
      width: 100%;
      height: 100%;
      display: inline-block;
      cursor: pointer;
    }

    .ham {
      position: absolute;
      left: rem(6);
      width: rem(26);
      height: rem(3);
      background: var(--navyblue);
      transition: all 0.2s ease-in;
      transform-origin: center center;

      &--b2b {
        &--1 {
          top: rem(11);
          background: var(--lime);
        }
        &--2 {
          top: rem(18);
          background: var(--lime);
        }
        &--3 {
          top: rem(25);
          background: var(--lime);
        }
      }
      &--b2c {
        &--1 {
          top: rem(11);
          background: var(--navyblue);
        }
        &--2 {
          top: rem(18);
          background: var(--navyblue);
        }
        &--3 {
          top: rem(25);
          background: var(--navyblue);
        }
      }
    }
  }
}

#menuSwitch + .header {
  .header {
    &__menu-items {
      pointer-events: none;

      @include screenSize(tablet) {
        pointer-events: all;
      }
    }
  }
}

#menuSwitch:checked + .header {
  .header {
    &__mobile-toggle {
      .ham {
        &--1 {
          transform: rotate(45deg);
          top: rem(18);
        }
        &--2 {
          transform: rotate(180deg);
          opacity: 0;
        }
        &--3 {
          transform: rotate(-45deg);
          top: rem(18);
        }
      }
    }

    &__menu-items {
      transform: translateY(0);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      padding-top: rem(126);
      opacity: 1;
      z-index: -1;
      background: var(--bg-color-webview);
      pointer-events: all;

      ul {
        padding: 0 rem(32);
        flex-direction: column;
      }
    }

    &__menu-item {
      @extend %headings-l3;
      padding: rem(24) rem(0);
      border-bottom: rem(1) solid $color-grey-100;
      font-size: rem(24);
      position: relative;

      &--selected {
        border-bottom: rem(1) solid $color-grey-100;
      }

      &:after {
        position: absolute;
        right: rem(16);
        top: rem(32);
        content: '';
        //background: no-repeat url('../../assets/arrow-right.svg');
        background-color: var(--text-color);

        -webkit-mask-image: url('../../assets/arrow-right.svg');
        mask-image: url('../../assets/arrow-right.svg');

        width: rem(12);
        height: rem(20);
      }
    }

    &__switch-language {
      transform: translateY(rem(70)) translateX(rem(20));
    }
  }
}
