.email-change-block-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: rem(16);
  padding: rem(16);

  @include screenSize(tablet) {
    max-width: rem(550);
  }

  &__icon {
    border-radius: 50%;
    background: #ffe9d3;
    width: rem(64);
    height: rem(64);

    display: flex;
    justify-content: center;
    align-items: center;
    margin: rem(16) rem(16) 0 rem(16);
  }

  h3 {
    @extend %headings-l3;
    margin: 0;
    padding: 0 rem(16);
  }

  p {
    @extend %body-body3;
    margin: 0;
    padding: 0 rem(16);
  }
  p:last-of-type {
    margin-bottom: rem(50);
  }

  button {
    width: 100%;
  }
}
